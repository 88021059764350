<template>
  <v-container
    class="pa-0 px-0 pt-0 grey lighten-4"
    style="max-width: 600px; margin: 0 auto; min-height: 800px"
  >
    <v-img src="@/assets/costco_cover.webp"></v-img>

    <div v-if="has_alcohol" class="my-4 text-center">
      <div class="text-subtitle-2">20歳未満の者の飲酒は法律で禁止されています。</div>
      <div class="text-subtitle-2">20歳未満の者に対して酒類を販売しません。</div>
    </div>

    <div v-if="!loaded" class="px-3 mt-3">
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="loaded">
      <div class="px-1 py-2 white">
        <div
          v-for="sku_key in sku_model.slice(1)"
          :key="sku_key"
          :class="border(sku_tree.find(sku_key).depth)"
          class="px-2 my-1"
        >
          <v-divider v-if="show_divider(sku_tree.find(sku_key).depth)"></v-divider>
          <v-chip-group
            mandatory
            column
            active-class="primary--text"
            v-model="sku_model[sku_tree.find(sku_key).depth]"
          >
            <v-chip
              v-for="child in sku_tree.find(sku_model[sku_tree.find(sku_key).depth - 1]).children"
              :key="child.key"
              :value="child.key"
              @click="sku_clicked(sku_tree.find(child.key))"
              :label="is_chip_label(sku_tree.find(sku_key).depth)"
              :small="is_chip_small(sku_tree.find(sku_key).depth)"
            >
              {{ child.value.category_name }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>

      <div ref="backToTopPoint"></div>

      <div class="back-to-top">
        <v-btn color="orange darken-2" v-show="showBackToTop" fab dark @click="backToTop()">
          <div class="back-to-top-btn">
            <v-icon x-large>mdi-chevron-up</v-icon>
            <span class="text-overline" style="line-height: 1.2">top</span>
          </div>
        </v-btn>
      </div>

      <div style="background-color: white">
        <v-row class="px-5 mb-2 py-2">
          <v-col
            style="position: relative"
            cols="6"
            v-for="product in showProducts"
            :key="product._id"
            class="px-1"
          >
            <div v-if="product.is_soldout" class="is-sold-out">
              <div>品切れ</div>
            </div>
            <AddToCart :product_id="product" />
            <v-card :id="product._id" @click="clicked(product._id)" elevation="0">
              <v-img
                class="white--text align-end ma-auto"
                :src="product.thumbnail"
                aspect-ratio="1"
                style="object-fit: cover; border-radius: 10px"
                gradient="0deg, rgba(0, 0, 0, 0.5) 15%, rgba(0, 0, 0, 0.11) 45%, rgba(0, 0, 0, 0) 86%"
              >
                <div
                  class="text-caption ml-1 mb-0 font-weight-bold line-clamp"
                  v-html="product.subtitle"
                ></div>
              </v-img>
              <v-card-text class="text-caption pa-0 pl-2 pt-1">
                <div class="text-truncate">
                  <span>{{ product.brand }}</span>
                </div>

                <div class="black--text font-weight-bold text-body-3 line-clamp">
                  {{ product.title }}
                </div>
                <div>
                  <v-chip v-if="product.min_sku.choice_name" class="mr-2" x-small label>
                    {{ product.min_sku.choice_name }}
                  </v-chip>
                </div>
                <div class="mx-1 my-1 black--text">
                  <div v-if="product.is_onsale" class="pt-2 text-body-1 deep-orange--text">
                    <div class="font-weight-bold">
                      <span class="text-decoration-line-through">
                        {{ product.price }}
                      </span>
                      <span>{{ ` ${Math.floor(product.sale_price || 0)} ` }}</span>
                      円
                    </div>
                    <div class="black--text text-caption" style="margin-top: -5px">
                      税込
                      <span class="text-decoration-line-through">
                        {{ Math.floor(product.price * (1 + product.tax)) }}
                      </span>
                      <span>{{ Math.floor(product.sale_price * (1 + product.tax)) }}</span>
                      円
                    </div>
                  </div>
                  <div v-else class="pt-2 text-body-1 deep-orange--text">
                    <div class="font-weight-bold">{{ product.price }}円</div>
                    <div class="black--text text-caption" style="margin-top: -5px">
                      税込{{ Math.floor(product.price * (1 + product.tax)) }}円
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-btn class="mt-6 mb-6" @click="to_next_tab" block>
          次のカテゴリへ
          <v-icon color="orange">mdi-arrow-right</v-icon>
          {{ this.nextNode?.value.category_name }}
        </v-btn>

        <div class="text-center">
          <router-link to="/articles/privacy" class="text-center caption text-decoration-underline">
            個人情報保護方針
          </router-link>
        </div>
        <div class="text-center">
          <router-link to="/articles/rules" class="text-center caption text-decoration-underline">
            特定商取引法表示項目
          </router-link>
        </div>
        <div class="text-center">
          <router-link to="/articles/terms" class="text-center caption text-decoration-underline">
            利用規約
          </router-link>
        </div>
      </div>
    </div>

    <v-dialog v-model="pay_dialog" persistent max-width="290">
      <v-card class="pb-6">
        <div class="text-end pr-3 py-2">
          <v-icon @click="close_dialog()">mdi-close</v-icon>
        </div>

        <v-card-text class="pb-1 text-center black--text font-weight-bold">
          ご注文ありがとうございます。
        </v-card-text>

        <v-card-text class="pb-1 text-center mx-1" v-if="!is_corporation">
          ご購入の手続きが完了されますと、LINE公式からご注文の受付確認メッセージが届きます。⸜( •⌄•)⸝
        </v-card-text>
        <v-card-text class="pb-1 text-center mx-1" v-if="is_corporation">
          ご購入の手続きが完了されますと、見積書は通常12時間以内にご担当者様のメールアドレスに送信されます。⸜(•⌄•
          )⸝
        </v-card-text>

        <!--             <div class="text-center mt-3">
              <v-btn href="https://lin.ee/Q0QoAIw"   outlined>
                LINE公式を確認する
              </v-btn>
            </div> -->
        <v-card-actions>
          <!--  -->
          <v-spacer></v-spacer>
          <!-- <v-btn color="green" :href="my_url()" text outlined>
            LINE公式を確認する
          </v-btn> -->
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import Tree from '@/api/GlobalData.js';
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import AddToCart from '@/components/AddToCart.vue';

  export default {
    name: 'Home',
    metaInfo: {
      title: 'レコセレ公式サイト',
      meta: [
        {
          name: 'description-',
          content: 'より良い生活を、より手頃に',
        },
      ],
    },
    data: () => ({
      loaded: false,
      products: [],
      pay_dialog: false,
      is_corporation: false,
      offsetTop: 0,
      /* category tree*/
      sku_model: [], //this the selected chain
      sku_tree: null,
      otameshi: [],
      discount_products:[],
      new_products:[],
      showBackToTop: false,
    }),
    components: {
      // QAComponent,
      AddToCart,
      BottomNavigation,
    },
    mounted() {
      if (this.$store.state.user.sku_model.length > 1) {
        this.sku_model = this.$store.state.user.sku_model;
      }
      if(this.discount_products.length==0&&this.sku_model[1]=='65dfdf1c44f0934854827edd'){
          this.sku_model[1] = '6551d4c01929233e4b24cdf6';
      }
      // console.log('stored', this.$store.state.user.sku_model);
      // console.log('restored', this.sku_model);
      // this.scrollToElement();
      window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    async created() {
      this.is_corporation = this.$store.state.user.is_corporation; //法人判断

      const information = (await Methods.getInformation('636bdde45d924db54bc53a71')).data;
      let response = await Methods.getProducts({
        is_archived: 0,
        sortField: 'is_soldout',
        sortOrder: 1,
      });
      const discount_products=response.data.products.filter((product) => product.is_onsale === true&&product.is_soldout==0);
      this.discount_products=discount_products;
    
      if (discount_products.length==0) {
        information.new_categories.forEach(child => {
          if (child.category_name === 'セール') 
            child.is_disabled = true;
        });
      }
      
      let tree = new Tree(information.new_categories, 1);
      this.sku_tree = tree;
    
      // let print = [...tree.preOrderTraversal()];
      // console.log('print tree', print);

      if (this.sku_model.length < 2) {
        this.sku_model = [...tree.getOneChain()].reverse();
      }

      for (let product of response.data.products) {
        /* set price to cheapest sku */
        let price = 100000;
        let min_sku = {};
        for (let sku of product.sku_objects) {
          if (sku.is_leaf == true) {
            if (sku.sku_product.price < price) {
              price = sku.sku_product.price;
              min_sku = sku;
            }
          }
        }
        product.price = price;
        if (product.is_onsale && product.sale_discount) {
          product.sale_price = Math.floor(price * product.sale_discount);
        }
        product.min_sku = min_sku;
        this.products.push(product);
      }

      let otameshi_ids = information.otameshi;
      let new_products_ids= information.new_products;

      otameshi_ids = otameshi_ids.filter((id) => {
        return response.data.products.find((product) => id === product._id);
      });

      new_products_ids = new_products_ids.reverse().filter((id) => {
        return response.data.products.find((product) => id === product._id);
      });

      this.otameshi = otameshi_ids.map((id) =>
        response.data.products.find((product) => id === product._id),
      );
      this.new_products = new_products_ids.map((id) =>
        response.data.products.find((product) => id === product._id),
      );

      this.loaded = true;
      // console.log('debug_count', debug_count);
      // console.log(this.products_by_category);

      /* fill tab titles*/
      // for (let key of Object.keys(this.products_by_category)) {
      //   key = Number(key) - 1;
      //   if (key >= 0) {
      //     this.tab_titles.push(this.categories[key]);
      //   }
      // }
      // console.log('titles', this.tab_titles);
    },
    computed: {
      has_alcohol() {
        return this.showProducts.some((item) => item.is_alcohol);
      },
      nextNode() {
        const [leaf1, leaf2, leaf3, leaf4] = this.sku_model;
        // const node = this.sku_tree.getNextLeafNode2(leaf4 || leaf3 || leaf2 || leaf1);
        const leaf_id = leaf4 || leaf3 || leaf2 || leaf1;
        const leafNodes = [
          ...[...this.sku_tree.postOrderTraversal()]?.filter((item) => item.value.is_leaf),
        ];
        const idx = leafNodes.findIndex((item) => item.value.is_leaf && item.key === leaf_id);
        //新着用 hard coding
        if(leaf_id==='65dfdf1c44f0934854827edd') return this.sku_tree.find('6551d4c01929233e4b24cdf6');
        if (leaf_id=='6551d4c01929233e4b24cdf6') return this.sku_tree.find('639f30182bb026ce5fb859d9')
        if (idx === leafNodes.length) return null;
        return leafNodes[idx + 1];
      },
      showProducts() {
        if(this.sku_model[this.sku_model.length - 1] == '65dfdf1c44f0934854827edd'){
          return this.discount_products;
        }
        else if (this.sku_model[this.sku_model.length - 1] == '639f30182bb026ce5fb859d9') {
          return this.otameshi;
        }
        else if (this.sku_model[this.sku_model.length - 1] == '6551d4c01929233e4b24cdf6') {
          return this.new_products;
        }
        // function shuffleArray(array) {
        //   return array.sort(() => Math.random() - 0.5);
        // }
        // return shuffleArray(
        //   this.products.filter((item) => {
        //     if (item?.new_category == this.sku_model[this.sku_model.length - 1]) {
        //       return true;
        //     }
        //   }),
        // );

        let productAns = this.products.filter((item) => {
          if (item?.new_category == this.sku_model[this.sku_model.length - 1]) {
            return true;
          }
        });
        productAns.sort((a, b) => b.order_num - a.order_num);
        productAns.sort((a, b) => {
          const isSoldoutA = a.is_soldout || 0;
          const isSoldoutB = b.is_soldout || 0;
          return isSoldoutA - isSoldoutB;
        });
        return productAns;
      },
    },
    watch: {
      $route() {
        if ('paid' in this.$route.query) {
          if (this.$route.query.paid == 'true') {
            this.pay_dialog = true;
            this.$store.commit('cart/setMyCart', []);
          }
        }
      },
    },
    methods: {
      // scrollToElement() {
      //   const interval = setInterval(() => {
      //     if (this.$store.state.user.home_scrollTop > window.document.body.scrollHeight) return;
      //     window.scrollTo({
      //       top: this.$store.state.user.home_scrollTop,
      //     });
      //     clearInterval(interval);
      //   }, 100);
      //   return interval;
      // },
      show_sku_tag: function (sku_objects) {
        if (sku_objects[0].choice_name.length > 1) {
          return true;
        }
        return false;
      },
      close_dialog: function () {
        this.pay_dialog = false;
        this.$router.push('/');
      },
      my_url: function () {
        if (navigator.userAgent.indexOf('Line') > -1) {
          return 'https://line.me/R/';
        } else {
          return 'https://line.me/R/oaMessage/@532dhaol/';
        }
      },
      title_index_TO_key: function (index) {
        return this.categories.indexOf(this.tab_titles[index]) + 1;
      },
      back_to_home: function () {
        this.$router.push('/');
      },
      clicked: function (id) {
        this.$router.push('/product/' + id);
      },
      backToTop: function () {
        window.scrollTo(0, 187);
      },
      handleScroll: function () {
        //滚动条到底部的条件
        if (!this.$refs.backToTopPoint) return;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.$store.commit('user/set_home_scrollTop', { scrollTop });
        if (this.$refs.backToTopPoint.getBoundingClientRect().y < -800) {
          this.showBackToTop = true;
        } else {
          this.showBackToTop = false;
        }
      },
      to_next_tab: function () {
        this.backToTop();
        const node = this.nextNode;
        let newSkuModel = [1];
        let temp = node;
        for (let index = 0; index < node.depth; index++) {
          newSkuModel[temp.depth] = temp.key || '1';
          temp = temp.parent;
        }
        this.sku_model = newSkuModel;
        this.$store.commit('user/set_sku_model', { sku_model: newSkuModel });
      },
      sku_clicked: function (node) {
        let that = this;

        // if ('sku_product' in node.value) {
        //   /* update sku_key */
        //   that.selected_sku_key = node.key;
        // }
        /* update sku_model */
        // console.log('sku click was executed');
        let back_part = [...that.sku_tree.getOneChain(that.sku_tree.find(node.key))].reverse();
        let head_part = that.sku_model.slice(0, node.depth);
        let model = head_part.concat(back_part);
        this.sku_model = model;
        // console.log(model);
        this.$store.commit('user/set_sku_model', { sku_model: this.sku_model });
      },
      border(depth) {
        if (depth == 1) {
          return 'left-border-red ';
        } else if (depth == 2) {
          return 'left-border-orange';
        } else {
          return 'left-border-yellow';
        }
      },
      is_chip_color(depth) {
        if (depth == 1) {
          return 'orange';
        } else {
          return '';
        }
      },
      is_chip_small(depth) {
        if (depth == 1) {
          return false;
        } else {
          return true;
        }
      },
      is_chip_label(depth) {
        if (depth != 2) {
          return false;
        } else {
          return true;
        }
      },
      show_divider(depth) {
        if (depth == 1) {
          return false;
        } else {
          return true;
        }
      },
    },
    beforeRouteLeave(to, from, next) {
      this.$route.meta.scrollHeight = document.documentElement.scrollTop;
      next();
    },
  };
</script>

<style scoped>
  .back-to-top {
    position: fixed;
    left: 20px;
    bottom: 70px;
    z-index: 1000;
  }
  .back-to-top-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .v-tabs {
    width: 100%;
    height: 100%;
  }

  .v-window {
    height: calc(100% - 48px);
    /* タブ領域の高さを引く */
  }

  .v-tab__items,
  .v-window-item,
  .v-window >>> div.v-window__container {
    /* ここが重要 */
    height: 100%;
  }
  .line-clamp {
    display: -webkit-box;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .left-border-red {
    background-color: #ffffff !important;
    border-left: 7px solid red !important;
  }
  .left-border-orange {
    background-color: #ffffff !important;
    border-left: 7px solid orange !important;
  }
  .left-border-yellow {
    background-color: #ffffff !important;
    border-left: 7px solid #ffd214 !important;
  }

  .is-sold-out {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #00000021;
  }
  .is-sold-out > div {
    width: 160px;
    height: 160px;
    line-height: 160px;
    margin: 20% auto auto;
    border: 6px solid #f57c00;
    border-radius: 50%;
    transform: rotate(-30deg);
    color: #f57c00;
    font-size: 42px;
    font-weight: 900;
    text-align: center;
    text-shadow: 2px 2px 1px white;
    box-shadow: 2px 2px 1px white;
  }

  @media screen and (max-width: 600px) {
    .is-sold-out > div {
      width: 140px;
      height: 140px;
      line-height: 140px;
      margin: 10% auto auto;
      border: 6px solid #f57c00;
      border-radius: 50%;
      transform: rotate(-30deg);
      color: #f57c00;
      font-size: 36px;
    }
  }
</style>
